import { Box, Typography, useTheme } from '@mui/material';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboHeader } from '../../../components/layout/ParkingaboHeader';
import { ParkingaboLayout } from '../../../components/layout/ParkingaboLayout';
import { AuthedRouteCompProps } from '../../RouteUtils';
import { OnboardingStepType, useOnboarding } from './OnboardingConfig';
import {
    PaymentMethodForm,
    PaymentMethodType,
} from '../../../components/forms/PaymentMethodForm';
import { Outlet } from 'react-router-dom';

export function OnboardingPaymentMethodRoute(_: AuthedRouteCompProps) {
    const theme = useTheme();
    const { config } = useOnboarding();
    const currentStep = config.steps.findIndex(
        c => c.type === OnboardingStepType.PAYMENT,
    );
    const totalSteps = config.steps.length;

    return (
        <ParkingaboLayout
            style={{
                justifyContent: 'flex-start',
                paddingLeft: 0,
                paddingRight: 0,
            }}
        >
            <Box
                style={{
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                }}
            >
                <ParkingaboHeader />
                <Typography
                    variant="h1"
                    style={{
                        marginTop: theme.spacing(3),
                    }}
                >
                    <Localized
                        de="Zahlungsmittel"
                        fr="Moyen de paiement"
                        it="Mezzo di pagamento"
                        en="Payment mean"
                    />
                </Typography>
                <Typography
                    variant="h3"
                    style={{
                        marginBottom: theme.spacing(5),
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Localized
                        de={`Konfiguration (${
                            currentStep + 1
                        } von ${totalSteps})`}
                        fr={`Configuration (${
                            currentStep + 1
                        } de ${totalSteps})`}
                        it={`Configurazione (${
                            currentStep + 1
                        } di ${totalSteps})`}
                        en={`Setup (${currentStep + 1} of ${totalSteps})`}
                    />
                </Typography>
            </Box>
            <PaymentMethodForm
                paymentMethodType={PaymentMethodType.ONBOARDING}
            />

            <Outlet />
        </ParkingaboLayout>
    );
}
