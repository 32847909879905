import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AliasInvalidReason } from '../shared/ParkingaboModels';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../routes/RouteUtils';

export function ProductsPurchaseAccessChecker({ user }: AuthedRouteCompProps) {
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const [routeChecked, setRouteChecked] = useState(false);

    useEffect(() => {
        if (!user.aliasInvalidReason) {
            setRouteChecked(true);
        }

        switch (user.aliasInvalidReason) {
            case AliasInvalidReason.ONBOARDING_REQUIRED:
                throw new Error('User bypassed onboarding');
            case AliasInvalidReason.NOT_FOUND:
            case AliasInvalidReason.LAST_DIRECT_FAILED:
            case AliasInvalidReason.EXPIRED:
                navigate(
                    generateAuthedParkingaboPath('products/payment-missing'),
                );
                break;
        }
        setRouteChecked(true);
    }, [user.aliasInvalidReason]);

    if (!routeChecked) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    } else {
        return <Outlet />;
    }
}
