import { Outlet, useNavigate } from 'react-router-dom';
import { AuthedRouteCompProps } from '../routes/RouteUtils';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export function TenantChecker({ user }: AuthedRouteCompProps) {
    const navigate = useNavigate();
    const { tenantId } = useParams<{ tenantId?: string }>();

    useEffect(() => {
        if (tenantId !== user.tenantId.toString()) {
            navigate(`/${user.tenantId}`);
        }
    }, [user.tenantId]);

    return <Outlet />;
}
