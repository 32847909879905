import { useEffect } from 'react';
import { useParams } from 'react-router';
import { PendingPaymentPoller } from '../../../components/PendingPaymentPoller';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import { useNavigate } from 'react-router-dom';
import { PaymentMethodType } from '../../../components/forms/PaymentMethodForm';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';

export function PaymentMethodCheckRoute({
    refetchUser,
    paymentMethodType,
}: { paymentMethodType: PaymentMethodType } & AuthedRouteCompProps) {
    const { transactionId } = useParams<{ transactionId: string }>();
    const searchParams = new URLSearchParams(window.location.search);
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const status = searchParams.get('status');
    const datatransTrxId = searchParams.get('datatransTrxId');
    const redirectOnCanceled = ((): string => {
        switch (paymentMethodType) {
            case PaymentMethodType.REGISTER:
                return generateAuthedParkingaboPath('payment/register/aborted');
            case PaymentMethodType.ONBOARDING:
                return generateAuthedParkingaboPath(
                    'onboarding/payment/aborted',
                );
        }
    })();
    const navigate = useNavigate();

    const [processDatatransRedirectState, initiateDatatransRedirectProcess] =
        useParkingaboServerWrite<{ datatransTransactionId: string }, unknown>(
            ({ datatransTransactionId }) => ({
                url: `/ui-api/parkingabo/user/self/payment/datatrans/${datatransTransactionId}/process-redirect`,
            }),
        );

    useEffect(() => {
        if (
            datatransTrxId !== null &&
            processDatatransRedirectState.status ===
                RequestStatus.NEVER_EXECUTED
        ) {
            initiateDatatransRedirectProcess({
                datatransTransactionId: datatransTrxId,
            });
        }
    }, [
        datatransTrxId,
        processDatatransRedirectState.status,
        initiateDatatransRedirectProcess,
    ]);

    if (!transactionId) {
        return null;
    }

    return (
        <PendingPaymentPoller
            pendingPaymentId={transactionId}
            refetchUser={refetchUser}
            cancelling={status === 'cancel'}
            twintPairingAborted={status === 'aborted'}
            onboarding={paymentMethodType === PaymentMethodType.ONBOARDING}
            onPaymentCanceled={() => {
                navigate(redirectOnCanceled);
            }}
        />
    );
}
