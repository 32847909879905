import { useParkingaboServerFetch } from '../../../api/ParkingaboApi';
import {
    Localized,
    useLanguage,
} from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { Box, Stack, Typography } from '@mui/material';
import { ParkingaboListItem } from '../../../components/ParkingaboMenuListItem';
import { currencyCentsToLocalPrice } from 'dg-web-shared/lib/NumberFormatter';
import { ParkingaboAsyncLoadedSection } from '../../../components/layout/ParkingaboAsyncLoadedSection';
import { StatusBar } from '../../../components/StatusBar';
import { DateTime } from 'luxon';
import { Formatter } from 'dg-web-shared/lib/Date';
import { ParkingaboOverlineList } from '../../../components/layout/ParkingaboOverlineList';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ErrorAlert } from 'dg-web-shared/common/components/material-ui/ErrorAlert';
import { Fragment } from 'react';

const enum ReportItemType {
    PRODUCT_PURCHASE = 'PRODUCT_PURCHASE',
    PRODUCT_REFUND = 'PRODUCT_REFUND',
    PRODUCT_SUBSCRIPTION_CHARGE = 'PRODUCT_SUBSCRIPTION_CHARGE',
    OFFSTREET_PURCHASE = 'OFFSTREET_PURCHASE',
    OFFSTREET_REFUND = 'OFFSTREET_REFUND',
    INPAYMENT = 'INPAYMENT',
    MANUAL = 'MANUAL',
}

interface AccountStatementMonthBucket {
    yearMonth: string;
    items: AccountStatementItem[];
}

interface AccountStatementItem {
    bkBookingId: number;
    type: ReportItemType;
    date: string;
    reportText: string[];
    postings: Posting[];
}

interface Posting {
    amount: number;
    amountRappen: number;
    vatInfo: PostingAmountInfo | null;
}

interface PostingAmountInfo {
    vatPercent: number;
    vatNumber: string;
    vatCompanyName: string;
}

export function AccountStatementOutlet() {
    const { language } = useLanguage();
    const [productState] = useParkingaboServerFetch<
        AccountStatementMonthBucket[]
    >(
        () => ({
            url: `/ui-api/parkingabo/user/self/monthly-report`,
        }),
        {},
    );

    return (
        <ParkingaboLayoutWithHeader
            backTo={'..'}
            title={
                <Localized
                    de="Kontoauszug"
                    fr="Relevé de compte"
                    it="Estratto conto"
                    en="Account statement"
                />
            }
            noGutter
            scrollable
        >
            <ParkingaboAsyncLoadedSection
                state={productState}
                render={monthBuckets => {
                    if (monthBuckets.length < 1) {
                        return (
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="stretch"
                                spacing={0}
                            >
                                <ParkingaboListItem>
                                    <Typography
                                        color="text.disabled"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Localized
                                            de="Keine Einträge gefunden."
                                            fr="Aucune entrée trouvée."
                                            it="Nessuna voce trovata."
                                            en="No records found."
                                        />
                                    </Typography>
                                </ParkingaboListItem>
                            </Stack>
                        );
                    }

                    return (
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            spacing={0}
                        >
                            {monthBuckets.map(bucket => (
                                <Fragment key={bucket.yearMonth}>
                                    <StatusBar key={bucket.yearMonth}>
                                        {Formatter.monthNameYear(
                                            DateTime.fromISO(bucket.yearMonth),
                                            Formatter.getLocaleFromString(
                                                language,
                                            ),
                                        )}
                                    </StatusBar>
                                    {bucket.items.map(item => (
                                        <ParkingaboListItem
                                            variableHeight
                                            key={item.bkBookingId}
                                            to={item.bkBookingId.toString()}
                                        >
                                            <StatementEntry item={item} />
                                        </ParkingaboListItem>
                                    ))}
                                </Fragment>
                            ))}
                            <AccountStatementDetailOutlet
                                context={{ monthBuckets: monthBuckets }}
                            />
                        </Stack>
                    );
                }}
            />
        </ParkingaboLayoutWithHeader>
    );
}

function StatementEntry({ item }: { item: AccountStatementItem }) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            width="100%"
        >
            <div>
                {item.reportText.map((text, index) => (
                    <Typography
                        key={index}
                        component={index === 0 ? 'h3' : 'div'}
                        fontWeight={index === 0 ? 'bold' : undefined}
                    >
                        {text}
                    </Typography>
                ))}
            </div>
            <Typography component="div" fontWeight="bold" textAlign="right">
                {currencyCentsToLocalPrice(
                    'de',
                    item.postings.reduce(
                        (acc, posting) => acc + 100 * posting.amount,
                        0,
                    ),
                    false,
                )}
            </Typography>
        </Stack>
    );
}

interface AccountStatementDetailOutletContext {
    monthBuckets: AccountStatementMonthBucket[];
}

export function useAccountStatementDetailContext() {
    return useOutletContext<AccountStatementDetailOutletContext>();
}

function AccountStatementDetailOutlet({
    context,
}: {
    context: AccountStatementDetailOutletContext;
}) {
    return <Outlet context={context} />;
}

export function AccountStatementItemDetail() {
    const { monthBuckets } = useAccountStatementDetailContext();
    const { bkBookingId } = useParams<{ bkBookingId: string }>();
    const item =
        monthBuckets
            .reduce(
                (acc: AccountStatementItem[], bucket) => [
                    ...acc,
                    ...bucket.items,
                ],
                [],
            )
            .find(
                item =>
                    bkBookingId &&
                    item.bkBookingId === parseInt(bkBookingId, 10),
            ) ?? null;

    if (item === null) {
        return (
            <AccountStatementModal>
                <ErrorAlert>
                    <Localized
                        de="Bewegung nicht gefunden."
                        fr="Mouvement non trouvé."
                        it="Movimento non trovato."
                        en="Movement not found."
                    />
                </ErrorAlert>
            </AccountStatementModal>
        );
    }

    const showVatInfoOnPrice =
        item.postings.length === 1 && item.postings[0].vatInfo !== null;

    return (
        <AccountStatementModal>
            <ParkingaboOverlineList.Body>
                <ParkingaboOverlineList.Item
                    label={
                        <Localized de="Datum" fr="Date" it="Data" en="Date" />
                    }
                >
                    {Formatter.dayMonthYear(DateTime.fromISO(item.date))}
                </ParkingaboOverlineList.Item>
                <ParkingaboOverlineList.Item
                    label={
                        <Localized
                            de="Beschreibung"
                            fr="Description"
                            it="Descrizione"
                            en="Description"
                        />
                    }
                >
                    {item.reportText.map(line => (
                        <div key={line}>{line}</div>
                    ))}
                </ParkingaboOverlineList.Item>
                <ParkingaboOverlineList.Item
                    label={
                        <Localized
                            de="Betrag"
                            fr="Montant"
                            it="Importo"
                            en="Amount"
                        />
                    }
                >
                    {currencyCentsToLocalPrice(
                        'de',
                        item.postings.reduce(
                            (amount, posting) => amount + posting.amountRappen,
                            0,
                        ),
                    )}
                    {showVatInfoOnPrice && (
                        <VatPercentText
                            vatPercent={item.postings[0].vatInfo!.vatPercent}
                        />
                    )}
                </ParkingaboOverlineList.Item>
                <ShowVatInfo
                    postingsWithVat={item.postings.filter(
                        posting => posting.vatInfo,
                    )}
                    vatInfoShownOnPrice={showVatInfoOnPrice}
                />
            </ParkingaboOverlineList.Body>
        </AccountStatementModal>
    );
}

function AccountStatementModal({ children }: { children: React.ReactNode }) {
    return (
        <ParkingaboRoutedModal
            open={true}
            backUrl=".."
            render={() => (
                <ParkingaboLayoutWithHeader
                    title={
                        <Localized
                            de="Kontoauszug"
                            fr="Relevé de compte"
                            it="Estratto conto"
                            en="Account statement"
                        />
                    }
                    scrollable
                    backTo=".."
                >
                    {children}
                </ParkingaboLayoutWithHeader>
            )}
        />
    );
}

function ShowVatInfo({
    postingsWithVat,
    vatInfoShownOnPrice,
}: {
    postingsWithVat: Posting[];
    vatInfoShownOnPrice: boolean;
}) {
    if (postingsWithVat.length < 1) {
        return null;
    }
    return (
        <ParkingaboOverlineList.Item
            label={
                <Localized
                    de="MWST-Info"
                    fr="Info TVA"
                    it="Info IVA"
                    en="VAT info"
                />
            }
        >
            {postingsWithVat.map(posting => (
                <Box key={posting.vatInfo!.vatNumber} sx={{ marginBottom: 2 }}>
                    {!vatInfoShownOnPrice && (
                        <div>
                            {currencyCentsToLocalPrice(
                                'de',
                                posting.amountRappen,
                            )}
                            <VatPercentText
                                vatPercent={posting.vatInfo!.vatPercent}
                            />
                        </div>
                    )}
                    <div>{posting.vatInfo!.vatNumber}</div>
                    <div>{posting.vatInfo!.vatCompanyName}</div>
                </Box>
            ))}
        </ParkingaboOverlineList.Item>
    );
}

function VatPercentText({ vatPercent }: { vatPercent: number }) {
    return (
        <Localized
            de={` (inkl. ${vatPercent}% MWST)`}
            fr={` (incl. ${vatPercent}% TVA)`}
            it={` (incl. ${vatPercent}% IVA)`}
            en={` (incl. ${vatPercent}% VAT)`}
        />
    );
}
