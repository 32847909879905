import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ParkingaboVehicleWithLicensePlate } from 'dg-web-shared/common/models/Vehicle';
import { ParkingaboUser } from '../shared/ParkingaboModels';
import { useParams } from 'react-router';
import { ParkingaboProductListItem } from '../shared/ParkingaboProductModels';

export interface Vehicles {
    data: ParkingaboVehicleWithLicensePlate[];
    status: RequestStatus;
    refetch: () => void;
}

export interface AuthedRouteCompProps {
    user: ParkingaboUser;
    refetchUser: () => void;
    products: ParkingaboProductListItem[];
    refetchProducts: () => void;
    logout: () => void;
    vehicles: Vehicles;
}

export function useParkingaboAuthedPathGeneration() {
    const { tenantId } = useParams<{ tenantId?: string }>();

    function generateAuthedParkingaboPath(path: string): string {
        return `/${tenantId}/` + path;
    }
    return generateAuthedParkingaboPath;
}
