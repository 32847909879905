import { ReactHookFormSelectOption } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormSelect';
import { LicensePlateCountry } from 'dg-web-shared/dto/LicensePlateCountry';
import { Language, makeLocalizedText } from 'dg-web-shared/lib/Localized';
import { useParkingaboServerFetch } from '../api/ParkingaboApi';

export function useCountryFetch() {
    return useParkingaboServerFetch<LicensePlateCountry[]>(
        () => ({
            url: `/ui-api/meta/license-plate-countries`,
        }),
        {},
    );
}

export function makeCountryOptions(
    countries: LicensePlateCountry[],
    language: Language,
): ReactHookFormSelectOption<string>[] {
    const translate = makeLocalizedText(language);

    return countries.map<ReactHookFormSelectOption<string>>(c => ({
        value: c.id,
        display: translate(c.name),
    }));
}
