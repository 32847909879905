import DriveEta from '@mui/icons-material/DriveEta';
import TwoWheeler from '@mui/icons-material/TwoWheeler';

import { VehicleLicensePlateType } from '../../models/Vehicle';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';

export const VehicleIconMap: {
    [key in VehicleLicensePlateType]: typeof DriveEta;
} = {
    [VehicleLicensePlateType.CAR]: DriveEta,
    [VehicleLicensePlateType.MOTORCYCLE]: TwoWheeler,
};

export function VehicleTypeIcon(
    props: React.ComponentProps<typeof SvgIcon> & {
        type?: VehicleLicensePlateType;
    },
) {
    const { type, ...iconProps } = props;
    if (!type) {
        return <>-</>;
    }
    const Icon = VehicleIconMap[type];
    return <Icon {...iconProps} />;
}
