import { useNavigate } from 'react-router-dom';
import { useParkingaboAuthedPathGeneration } from '../../RouteUtils';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';

export function NewPaymentRegistrationRequiredRoute() {
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    return (
        <FeedbackPopup
            open={true}
            color="warning"
            onAbort={() => {
                navigate(generateAuthedParkingaboPath('products'));
            }}
            abortLabel={
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            }
            onConfirm={() => {
                navigate(generateAuthedParkingaboPath('payment/register'));
            }}
            confirmLabel={
                <Localized
                    de="Weiter"
                    fr="Continuer"
                    it="Avanti"
                    en="Proceed"
                />
            }
            title={
                <Localized
                    de="Zahlungsmittel fehlt"
                    fr="Moyen de paiement manquant"
                    it="Mezzo di pagamento mancante"
                    en="Missing payment mean"
                />
            }
        >
            <Localized
                de="Bevor Sie ein neues Produkt hinzufügen können, müssen Sie ein gültiges Zahlungsmittel registrieren."
                fr="Avant de pouvoir ajouter un nouveau produit, vous devez enregistrer un moyen de paiement valide."
                it="Prima di poter aggiungere un nuovo prodotto, deve registrare un mezzo di pagamento valido."
                en="Before you can add a new product, you must register a valid means of payment."
            />
        </FeedbackPopup>
    );
}
